<script lang="ts">
	let iconClass = "";
	export { iconClass as class };
	export let icon: string;

	let element: HTMLDivElement | null = null;
	let handledManually = false;
	function onIconChange(element: HTMLDivElement, icon: string): void {
		// This causes the inner html to get duplicated, so we need to remove the old one when this triggers.
		handledManually = true;
		element.innerHTML = icon;
	}
	$: {
		if (element) {
			// @html tag does not correctly update on mount. this updates it correctly.
			onIconChange(element, icon);
		}
	}
	/* eslint-disable svelte/no-at-html-tags */
</script>

<div class="icon shrink-0 [&>svg]:h-full [&>svg]:w-full {iconClass}" bind:this={element}>
	{#if !handledManually}
		{@html icon}
	{/if}
</div>
